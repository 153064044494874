import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import styledProps from "styled-props"

// Styled Props
import { iconSize } from "../../styles/props"

// Styled Components
export const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: ${styledProps(iconSize)};
  height: ${styledProps(iconSize)};

  cursor: ${props => (props.onClick ? "pointer" : "inherit")};
`

const IconInner = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: contain;

  transition: filter 200ms ease-in-out, transform 200ms ease-in-out;

  ${props =>
    props.isWhite &&
    css`
      filter: brightness(0) invert(1);
    `}
`

const Icon = ({ src, alt, white, ...props }) => {
  return (
    <IconWrapper {...props}>
      <IconInner isWhite={white} src={src} alt={alt}></IconInner>
    </IconWrapper>
  )
}

Icon.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  white: PropTypes.bool,
}

Icon.defaultProps = {
  src: "",
  alt: "",
  white: false,
}

export default Icon
