import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import smoothscroll from "smoothscroll-polyfill"
import { media } from "styled-bootstrap-grid"

// Components
import Navbar from "./navbar"
import Footer from "./footer"
import Copyright from "./copyright"

// Styled
const Wrapper = styled.div`
  overflow-x: hidden;
  padding-top: ${({ theme }) => theme.sizes.navHeightMobile};
  ${media.sm`
    padding-top: ${({ theme }) => theme.sizes.navHeight};
  `};
`

const Layout = ({ children }) => {
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  return (
    <Wrapper>
      <Navbar />
      <main>{children}</main>
      <Footer />
      <Copyright />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
