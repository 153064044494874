import React from "react"
import styled from "styled-components"

// Styled
import { Container } from "styled-bootstrap-grid"
import { Text } from "../components/styled/typography"

const CopyrightWrapper = styled.aside`
  width: 100%;
  padding: 0.5rem;
  background: ${({ theme }) => theme.colors.light};
`

const CopyrightText = styled(Text)`
  a {
    color: inherit;
  }
`

const Copyright = ({ ...props }) => {
  return (
    <CopyrightWrapper {...props}>
      <Container>
        <CopyrightText align="center" size="12px">
          &copy; Copyright {new Date().getFullYear()}, Kow-Bud | Projekt i
          wykonanie:{" "}
          <a rel="nofollow" href="https://www.karolwaliszewski.pl/">
            Karol Waliszewski
          </a>
        </CopyrightText>
      </Container>
    </CopyrightWrapper>
  )
}

export default Copyright
