import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import styledProps from "styled-props"
import hexToRgba from "../../utils/hexToRgba"
import { darken } from "polished"
import { Link } from "gatsby"
import { IconWrapper } from "./icon"

// Styled Props
import {
  color,
  contrastColor,
  invertTextColor,
  iconFilter,
} from "../../styles/props"

// Styled Components
const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.8rem 1.4rem;

  background: ${props => (props.outline ? "transparent" : styledProps(color))};
  color: ${props =>
    props.outline ? styledProps(invertTextColor) : styledProps(contrastColor)};
  border: 1px solid ${styledProps(color)};
  border-radius: 0px;

  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;

  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.7" : "1")};

  transition: background-color ease 250ms, box-shadow ease 250ms;

  &:hover {
    background-color: ${props =>
      darken(0.07, styledProps(color)(props).trim())};
    box-shadow: 0 6px 15px
      ${props =>
        hexToRgba(darken(0.07, styledProps(color)(props).trim()), 0.25)};
    color: ${props => styledProps(contrastColor)};

    img {
      filter: ${props => (props.outline ? styledProps(iconFilter) : "none")};
    }
  }

  ${props =>
    props.full &&
    css`
      width: 100%;
    `}

  ${props =>
    props.withIcon &&
    css`
      position: relative;

      ${IconWrapper} {
        position: absolute;
        right: ${props.withIcon === "right" ? "1rem" : "initial"};
        left: ${props.withIcon !== "right" ? "1rem" : "initial"};
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    `}
`

const ButtonComponent = ({ to, children, ...props }) => {
  if (to) {
    return (
      <Button {...props} as={Link} to={to}>
        {children}
      </Button>
    )
  }

  return <Button {...props}>{children}</Button>
}

ButtonComponent.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  withIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  transparent: PropTypes.bool,
  big: PropTypes.bool,
}

ButtonComponent.defaultProps = {
  big: false,
}

export default ButtonComponent
