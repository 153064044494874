import React from "react"
import styled from "styled-components"

const ImageInner = styled.img`
  max-width: 100%;
  max-height: 100%;
`

const ImageInnerCover = styled(ImageInner)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.center ? "center" : "initial")};

  min-height: ${props =>
    props.minHeight ? `${props.minHeight}px` : "initial"};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : "initial")};
  width: ${props => (props.width ? `${props.width}px` : "100%")};
  height: ${props => (props.height ? `${props.height}px` : "100%")};
  max-height: 100%;
  max-width: 100%;
`

const Image = ({ src, alt, onError, cover, customRef, ...props }) => {
  if (cover)
    return (
      <ImageWrapper {...props}>
        <ImageInnerCover onError={onError} src={src} alt={alt} ref={customRef} />
      </ImageWrapper>
    )

  return (
    <ImageWrapper {...props}>
      <ImageInner onError={onError} src={src} alt={alt} ref={customRef} />
    </ImageWrapper>
  )
}

export default Image
