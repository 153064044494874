import theme from "./theme"

export const color = {
  secondary: theme.colors.secondary,
  light: theme.colors.light,
  primary: theme.colors.primary,
  dark: theme.colors.dark,
  white: theme.colors.white,
}

export const invertTextColor = {
  secondary: theme.colors.secondary,
  light: theme.colors.dark,
  primary: theme.colors.primary,
  dark: theme.colors.dark,
  white: theme.colors.white,
}

export const contrastColor = {
  secondary: theme.colors.white,
  light: theme.colors.secondary,
  primary: theme.colors.white,
  dark: theme.colors.white,
  white: theme.colors.dark,
}

export const iconFilter = {
  secondary: "brightness(0) invert()",
  primary: "brightness(0) invert()",
  light: "none",
}

export const iconSize = {
  tiny: "14px",
  small: "18px",
  medium: "24px",
  large: "30px",
  huge: "48px",
}
