import styled from "styled-components"
import styledProps from "styled-props"
import { media } from "styled-bootstrap-grid"
import { darken } from "polished"
import { Link as GatsbyLink } from "gatsby"

// Styled Props
import { color } from "../../styles/props"

export const Title = styled.h2`
  color: ${props => {
    if (props.color) return props.color

    let textColor = styledProps(color)(props)

    if (textColor) {
      return textColor
    }

    return props.theme.colors.text
  }};
  text-transform: ${props => (props.uppercase ? "uppercase" : "initial")};
  text-decoration: none;
  font-weight: ${props => props.weight || "700"};
  text-align: ${props => props.align || "inherit"};
  font-size: ${props => props.size || "1.6rem"};
  margin: ${props => (props.marginless ? "0" : "0.5rem 0")};

  ${media.sm`
    font-size: ${props => props.size || "1.5rem"};
  `}

  ${media.md`
    font-size: ${props => props.size || "1.75rem"};
  `}

  ${media.lg`
    font-size: ${props => props.size || "2rem"};
  `}
`

export const Subtitle = styled.h3`
  color: ${props => {
    if (props.color) return props.color

    let textColor = styledProps(color)(props)

    if (textColor) {
      return textColor
    }

    return props.theme.colors.text
  }};
  text-transform: ${props => (props.uppercase ? "uppercase" : "initial")};
  text-decoration: none;
  font-weight: ${props => props.weight || "600"};
  font-size: ${props => props.size || "1.25rem"};
  text-align: ${props => props.align || "inherit"};
  margin: ${props => (props.marginless ? "0" : "0.5rem 0")};

  ${media.sm`
    font-size: ${props => props.size || "1.3rem"};
  `}

  ${media.md`
    font-size: ${props => props.size || "1.375rem"};
  `}
`

export const Text = styled.p`
  color: ${props => {
    if (props.color) return props.color

    let textColor = styledProps(color)(props)

    if (textColor) {
      return textColor
    }

    return props.theme.colors.text
  }};
  text-transform: ${props => (props.uppercase ? "uppercase" : "initial")};
  text-decoration: none;
  font-weight: ${props => props.weight || "400"};
  font-size: ${props => props.size || "15px"};
  line-height: ${props => props.line || "20px"};
  text-align: ${props => props.align || "inherit"};
  margin: ${props => (props.marginless ? "0" : "0.7rem 0")};
`

export const InteractiveText = styled(Text)`
  display: block;
  cursor: pointer;

  transition: color 250ms ease;

  &:hover {
    color: ${props => {
      if (props.color) return darken(0.07, props.color)
      props.dark = true
      return styledProps(color)(props)
    }};
  }
`

export const Link = styled(Text).attrs(props => ({
  as: GatsbyLink,
}))`
  display: ${props => (props.align ? "block" : "inline-block")};
  text-decoration: none;

  transition: color 250ms ease;

  &:hover {
    color: ${props => {
      if (props.color) return darken(0.07, props.color)
      props.secondary = true
      return styledProps(color)(props)
    }};
  }
`

export const Bold = styled.span`
  font-weight: ${props => props.weight || "500"};
`

export const LineThrough = styled.span`
  text-decoration: line-through;
`

export const Content = styled.article`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  img {
    height: auto;
    max-width: 100%;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
    margin: 0.5rem 0;
  }

  ul {
    list-style-type: none;
  }

  ul li {
    display: flex;
    align-items: center;
  }

  ul li::before {
    content: "■";
    color: ${({ theme }) => theme.colors.primary};
    display: inline-block;

    width: 1em;
    margin-left: -1em;
  }
`
