import React, { Component } from "react"
import styled, { css } from "styled-components"
import { math, rgba } from "polished"
import hexToRgba from "../utils/hexToRgba"

// Components
import { Link } from "gatsby"

// Styled
import { Margin } from "styled-components-spacing"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import { CustomCol } from "../components/styled/layout"
import Image from "../components/styled/image"
import Icon from "../components/styled/icon"
import { Middle, Hidden } from "../components/styled/layout"

// Assets
import closeIcon from "../images/icons/close.svg"
import logo from "../images/logo.png"

const NavWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;

  width: 100%;
  max-width: 100vw;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 20px ${({ theme }) => hexToRgba(theme.colors.dark, 0.08)};

  transform: ${props =>
    props.navHidden
      ? `translate3d(0,${math(props.theme.sizes.navHeight + "* -1")},0)`
      : "none"};

  transition: transform ease-in-out 250ms;
`

const NavRow = styled(Row)`
  height: ${({ theme }) => theme.sizes.navHeightMobile};
  ${media.sm`
    height: ${({ theme }) => theme.sizes.navHeight};
  `};
`

const NavLogo = styled(Link)`
  display: flex;
  align-items: center;

  padding: 0.85rem 1rem;

  height: ${({ theme }) => theme.sizes.navHeightMobile};
  ${media.sm`
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    height: ${({ theme }) => theme.sizes.navHeight};
  `};
`

const NavLogoMobile = styled(NavLogo)`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.85rem 1rem;
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: ${({ theme }) => theme.sizes.navHeightMobile};
  ${media.sm`
    height: ${({ theme }) => theme.sizes.navHeight};
  `};
`

const NavLinksMobile = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.active ? `0` : "-110vw")};

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  background: ${({ theme }) => rgba(theme.colors.white, 0.95)};

  width: 100vw;
  height: 100vh;

  padding: 2rem;
  padding-top: calc(2rem + ${({ theme }) => theme.sizes.navHeightMobile});

  transform: ${props =>
    props.navHidden
      ? `translate3d(0,${props.theme.sizes.navHeight},0)`
      : "none"};

  transition: transform ease-in-out 250ms, left ease-in-out 350ms;
  ${props =>
    true &&
    `
    ${NavLink},${Middle} {
      transition: color ease 250ms, 500ms 200ms ease transform, 500ms 200ms ease-in-out opacity;
      transform: translateY(-40px);
      opacity: 0;
    }

    
  `}

  ${props =>
    props.active &&
    `
    ${NavLink},${Middle} {
      transform: translateY(0);
      opacity: 1;
    }
  `}
`

const NavLink = styled(Link)`
  position: relative;
  display: block;

  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;
  font-size: ${props => props.fontSize || "14px"};
  text-align: center;
  padding: 3px 4px;
  margin: 3px 6px;

  ${media.xxl`
    font-size: ${props => props.fontSize || "16px"};
    padding: 6px 8px;
    margin: 6px 6px;
  `}

  text-decoration: none;

  transition: color ease 250ms, 500ms 200ms ease transform,
    500ms 200ms ease-in-out opacity;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;

    width: 0px;
    height: 2.5px;

    background: ${({ theme }) => theme.colors.primary};

    transition: width ease 250ms;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};

    &::after {
      width: 50%;
    }
  }
`

const NavLinkLeft = styled(NavLink)`
  text-align: left;
`

const NavHamburger = styled.button`
  background: none;
  border: none;

  width: 20px;
  padding: 0;
  cursor: pointer;
`

const NavHamburgerLine = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  margin-left: auto;
  margin-bottom: 4px;

  background: ${({ theme }) => theme.colors.text};
`

const NavMobile = styled(Middle)`
  justify-content: flex-end;
`

const NavClose = styled.button`
  background: none;
  border: none;
  outline: none;

  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  padding: 0.5rem;
`

const NavDropdownContent = styled.div`
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 6px 10px;

  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 18px 20px ${({ theme }) => hexToRgba(theme.colors.dark, 0.08)};

  ${({ navHidden }) =>
    navHidden &&
    css`
      opacity: 0 !important;
      visibility: hidden !important;
    `}
`

const NavDropdown = styled.div`
  position: relative;

  &:hover {
    ${NavDropdownContent} {
      opacity: 1;
      visibility: visible;
    }
  }
`

class Navbar extends Component {
  constructor() {
    super()
    this.state = {
      navMobileActive: false,
      navHidden: false,
    }
    if (typeof window !== `undefined`) {
      this.prevScroll = window.pageYOffset
    }
    this.setMobileActive = this.setMobileActive.bind(this)
    this.checkScroll = this.checkScroll.bind(this)
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", this.checkScroll)
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("scroll", this.checkScroll)
    }
  }

  checkScroll() {
    if (typeof window !== `undefined`) {
      var currentScrollPos = window.pageYOffset
      if (this.prevScroll > currentScrollPos) {
        this.setState({ navHidden: false })
      } else if (currentScrollPos >= 80) {
        this.setState({ navHidden: true })
      }
      this.prevScroll = currentScrollPos
    }
  }

  setMobileActive(value) {
    this.setState({ navMobileActive: value })
  }

  render() {
    const { navHidden, navMobileActive } = this.state

    return (
      <NavWrapper navHidden={navHidden}>
        <Container>
          <NavRow justifyContent="between" mdJustifyContent="start">
            <Col auto md="3" noGutter alignSelf="center">
              <NavLogo to="/">
                <Image src={logo} alt="Kow-Bud" width={180}></Image>
              </NavLogo>
            </Col>
            <Col col hiddenMdDown alignSelf="center">
              <NavLinks>
                <NavLink to="/#o-nas">O firmie</NavLink>
                <NavDropdown>
                  <NavLink to="/#oferta">Oferta</NavLink>
                  <NavDropdownContent navHidden={navHidden}>
                    <Margin bottom={2}>
                      <NavLinkLeft to="/ogrodzenia-betonowe">
                        Ogrodzenia betonowe
                      </NavLinkLeft>
                    </Margin>
                    <Margin bottom={2}>
                      <NavLinkLeft to="/ogrodzenia-panelowe">
                        Oferta panelowe
                      </NavLinkLeft>
                    </Margin>
                    <Margin bottom={2}>
                      <NavLinkLeft to="/bramy-i-furtki">
                        Bramy i furtki
                      </NavLinkLeft>
                    </Margin>
                  </NavDropdownContent>
                </NavDropdown>
                <NavLink to="/realizacje">Realizacje</NavLink>
                <NavLink to="/#kontakt">Kontakt</NavLink>
              </NavLinks>
            </Col>
            <CustomCol col alignSelf="center" hiddenAboveAnd="md">
              <NavMobile>
                <NavHamburger
                  onClick={() => this.setMobileActive(!navMobileActive)}
                >
                  <NavHamburgerLine></NavHamburgerLine>
                  <NavHamburgerLine></NavHamburgerLine>
                  <NavHamburgerLine></NavHamburgerLine>
                  {/* <NavHamburgerText>Menu</NavHamburgerText> */}
                </NavHamburger>
              </NavMobile>
            </CustomCol>
          </NavRow>
          <Hidden hiddenAboveAnd="md">
            <NavLinksMobile navHidden={navHidden} active={navMobileActive}>
              <NavLogoMobile to="/">
                <Image src={logo} width={260} alt="Kow-Bud"></Image>
              </NavLogoMobile>
              <NavClose onClick={() => this.setMobileActive(false)}>
                <Icon small src={closeIcon}></Icon>
              </NavClose>

              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/#o-nas">O firmie</NavLink>
              </Margin>
              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/ogrodzenia-betonowe">Ogrodzenia betonowe</NavLink>
              </Margin>
              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/ogrodzenia-panelowe">Oferta panelowe</NavLink>
              </Margin>
              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/bramy-i-furtki">Bramy i furtki</NavLink>
              </Margin>
              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/realizacje">Realizacje</NavLink>
              </Margin>
              <Margin bottom={{ xs: 3, sm: 5 }}>
                <NavLink to="/#kontakt">Kontakt</NavLink>
              </Margin>
            </NavLinksMobile>
          </Hidden>
        </Container>
      </NavWrapper>
    )
  }
}

export default Navbar
