import React from "react"
import styled, { css } from "styled-components"
import { darken } from "polished"
import { Link } from "gatsby"

// Assets
import logo from "../images/logo.png"
import mailIcon from "../images/icons/email-dark.svg"
import phoneIcon from "../images/icons/phone-dark.svg"

// Styled
import Image from "../components/styled/image"
import Icon from "../components/styled/icon"
import { Padding, Margin } from "styled-components-spacing"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import { Text } from "../components/styled/typography"

const FooterWrapper = styled.footer`
  width: 100%;
  padding: 3.5rem 1rem 2.5rem 1rem;
  background: ${({ theme }) => theme.colors.light};

  ${media.md`
    padding: 4.5rem 1rem 5rem 1rem;
  `}
`
const FooterHeading = styled(Text).attrs(props => ({
  as: "h3",
  size: "18px",
  weight: 600,
}))`
  margin-top: 0;
`

const FooterText = styled(Text)`
  display: flex;
  align-items: center;
  ${({ first }) =>
    first &&
    css`
      margin-top: 0;
    `};

  margin-bottom: 0.8rem;
`

const FooterLink = styled(FooterText).attrs(props => ({
  as: Link,
}))`
  display: block;
  text-decoration: none;
  transition: color ease 200ms;

  &:hover {
    color: ${props => darken(0.1, props.theme.colors.primary)};
  }
`

const FooterLogo = styled(Link)`
  display: block;
`

const Footer = ({ ...props }) => {
  return (
    <FooterWrapper {...props}>
      <Container>
        <Row>
          <Col col="12" md="6" lg="4">
            <Padding bottom={{ xs: 3, lg: 0 }}>
              <FooterLogo to="/">
                <Image src={logo} alt="Kow-Bud" width={160}></Image>
              </FooterLogo>
              <FooterText>Przedsiębiorstwo wielobranżowe</FooterText>
              <FooterText
                href="https://goo.gl/maps/9X7MYinx9i6YumCR8"
                as="a"
                target="_blank"
              >
                63-611, Mroczeń 151A
              </FooterText>
            </Padding>
          </Col>
          <Col col="12" md="6" lg="2">
            <Padding bottom={{ xs: 3, lg: 0 }}>
              <FooterLink to="/#o-nas" first={true}>
                O firmie
              </FooterLink>
              <FooterLink to="/#oferta">Oferta</FooterLink>
              <FooterLink to="/realizacje">Realizacje</FooterLink>
              <FooterLink to="/#kontakt">Kontakt</FooterLink>
            </Padding>
          </Col>
          <Col col="12" md="6" lg="2" xl="3">
            <Padding bottom={{ xs: 3, lg: 0 }}>
              <FooterHeading>Godziny pracy:</FooterHeading>
              <FooterText>Pon - Pt: 8:00 - 16:00</FooterText>
              <FooterText>Sobota: 8:00 - 12:00</FooterText>
            </Padding>
          </Col>
          <Col col="12" md="6" lg="4" xl="3">
            <Padding bottom={{ xs: 3, lg: 0 }}>
              <FooterHeading>Kontakt:</FooterHeading>
              <FooterText href="mailto:kowbud@o2.pl" as="a">
                <Margin horizontal="1" inline>
                  <Icon src={mailIcon} tiny></Icon>
                </Margin>
                kowbud@o2.pl
              </FooterText>
              <FooterText
                href="mailto:ogrodzeniabeatakowalczyk@gmail.com"
                as="a"
                size="14px"
                line="12px"
              >
                <Margin horizontal="1" inline>
                  <Icon src={mailIcon} tiny></Icon>
                </Margin>
                ogrodzeniabeatakowalczyk@gmail.com
              </FooterText>
              <FooterText
                href="tel:+48604781485"
                as="a"
                size="14px"
                line="14px"
              >
                <Margin horizontal="1" inline>
                  <Icon src={phoneIcon} tiny></Icon>
                </Margin>
                +48 604 781 485 <br />
                (Ogrodzenia betonowe - Sławek)
              </FooterText>
              <FooterText
                href="tel:+48784072412"
                as="a"
                size="14px"
                line="14px"
              >
                <Margin horizontal="1" inline>
                  <Icon src={phoneIcon} tiny></Icon>
                </Margin>
                +48 784 072 412 <br />
                (Ogrodzenia panelowe - Beata)
              </FooterText>
            </Padding>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
