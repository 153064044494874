import React, { useState, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
import { Col, media } from "styled-bootstrap-grid"
import theme from "../../styles/theme"

export const Middle = styled.div`
  display: flex;
  align-items: center;

  flex-direction: ${props => (props.column ? "column" : "row")};

  ${props =>
    props.full &&
    css`
      width: 100%;
    `}
`

export const Right = styled(Middle)`
  justify-content: flex-end;

  align-items: ${props => (props.column ? "flex-end" : "center")};
`

export const Between = styled(Middle)`
  justify-content: space-between;
`

export const Around = styled(Middle)`
  justify-content: space-around;
`

export const Center = styled(Middle)`
  justify-content: center;
`

export const Section = styled.section`
  padding: ${({ theme }) => theme.sizes.navHeight} 0;
  margin: 1rem 0;
  ${({ topMarginless }) =>
    topMarginless &&
    css`
      margin: 0 0 1rem;
      padding: 0 0 2rem;
      ${media.sm`
        padding: 2rem 0;
        margin: 1rem 0;
      `}
    `}
  ${({ totalMarginless }) =>
    totalMarginless &&
    css`
      margin: 0;
      padding: 0;
    `}
  ${({ withSquares }) =>
    withSquares &&
    css`
      margin-bottom: 5rem;
      ${media.md`
        margin-bottom: 1rem;
      `}
    `}
`

export const CustomCol = styled(Col)`
  ${props =>
    props.hiddenAbove &&
    `
    @media (min-width: ${`${
      props.theme.breakpoints[props.hiddenAbove] + 1
    }px`}) {
      display: none;
    }
  `}

${props =>
  props.hiddenAboveAnd &&
  `
    @media (min-width: ${`${
      props.theme.breakpoints[props.hiddenAboveAnd]
    }px`}) {
      display: none;
    }
  `}

  ${props =>
    props.hiddenBelow &&
    `
    @media (max-width: ${`${
      props.theme.breakpoints[props.hiddenBelow] - 1
    }px`}) {
      display: none;
    }
  `}

${props =>
  props.hiddenBelowAnd &&
  `
    @media (max-width: ${`${
      props.theme.breakpoints[props.hiddenBelowAnd]
    }px`}) {
      display: none;
    }
  `}
`

const HiddenWrapper = styled.div`
 ${props =>
   props.full &&
   `
    width: 100%;
  `}

  ${props =>
    props.hiddenAbove &&
    `
    @media (min-width: ${`${
      props.theme.breakpoints[props.hiddenAbove] + 1
    }px`}) {
      display: none;
    }
  `}

${props =>
  props.hiddenAboveAnd &&
  `
    @media (min-width: ${`${
      props.theme.breakpoints[props.hiddenAboveAnd]
    }px`}) {
      display: none;
    }
  `}

  ${props =>
    props.hiddenBelow &&
    `
    @media (max-width: ${`${
      props.theme.breakpoints[props.hiddenBelow] - 1
    }px`}) {
      display: none;
    }
  `}

${props =>
  props.hiddenBelowAnd &&
  `
    @media (max-width: ${`${
      props.theme.breakpoints[props.hiddenBelowAnd]
    }px`}) {
      display: none;
    }
  `}

  ${props =>
    props.isHidden &&
    `{
      display: none;
    }
  `}
`

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export const Hidden = ({
  hiddenBelow,
  hiddenBelowAnd,
  hiddenAbove,
  hiddenAboveAnd,
  full,
  children,
  ...props
}) => {
  let size = useWindowSize()

  if (hiddenBelowAnd != null && theme.breakpoints[hiddenBelowAnd]) {
    if (size.width && size.width <= theme.breakpoints[hiddenBelowAnd]) {
      return null
    }

    return (
      <HiddenWrapper full={full} hiddenBelowAnd={hiddenBelowAnd} {...props}>
        {children}
      </HiddenWrapper>
    )
  }

  if (hiddenBelow != null && theme.breakpoints[hiddenBelow]) {
    if (size.width && size.width < theme.breakpoints[hiddenBelow]) {
      return null
    }

    return (
      <HiddenWrapper full={full} hiddenBelow={hiddenBelow} {...props}>
        {children}
      </HiddenWrapper>
    )
  }

  if (hiddenAboveAnd != null && theme.breakpoints[hiddenAboveAnd]) {
    if (size.width && size.width >= theme.breakpoints[hiddenAboveAnd]) {
      return null
    }

    return (
      <HiddenWrapper full={full} hiddenAboveAnd={hiddenAboveAnd} {...props}>
        {children}
      </HiddenWrapper>
    )
  }

  if (hiddenAbove != null && theme.breakpoints[hiddenAbove]) {
    if (size.width && size.width > theme.breakpoints[hiddenAbove]) {
      return null
    }

    return (
      <HiddenWrapper full={full} hiddenAbove={hiddenAbove} {...props}>
        {children}
      </HiddenWrapper>
    )
  }

  return children
}

export const Separator = styled.hr`
  display: block;
  width: 100%;
  height: 1px;
  background: ${props => (props.color ? props.color : props.theme.colors.gray)};
  border: none;
  margin: 0.5rem 0;
`

export const Line = styled.div`
  height: ${props => props.height || "3px"};
  width: ${props => props.width || "65px"};
  background: ${props =>
    props.color ? props.color : props.theme.colors.primary};
  border: none;

  margin: ${props => (props.center ? "0 auto" : "0")};
`

export const SwiperStyles = createGlobalStyle`
  :root {
    --swiper-theme-color: ${({ theme }) => theme.colors.primary};
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-button-prev, .swiper-button-next{
    outline: none;
    color: ${({ theme }) => theme.colors.dark};
  }

  .swiper-slide-duplicate {
        picture {
            img {
              opacity: 1 !important;
            }
            img[aria-hidden="true"]{
              opacity: 0 !important;
            }
            
        }
    }
`
